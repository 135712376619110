import { Injectable } from '@angular/core'
import { OrderTypeEnum } from '../enums/order-type.enum'
import { OrderClassEnum } from '../enums/order-class.enum'
import { OrderInterface } from '../interfaces/order.interface'
import { OrderStatusEnum } from '../enums/order-status.enum'
import { OrderAttributeEnum } from '../enums/order-attribute.enum'
import { OrderStatusRu } from '../consts/order-status-ru'
import { OrderSideRu } from '../consts/order-side-ru'
import { PositionInterface } from '../../position-entity/interfaces/position.interface'
import { OrderSideEnum } from '../enums/order-side.enum'
import { PositionSideEnum } from '../../position-entity/enums/position-side.enum'

@Injectable({ providedIn: 'root' })
export class OrderGeneralHelperService {

	protected readonly orderTypeEnum = OrderTypeEnum
	protected readonly orderClassEnum = OrderClassEnum
	protected readonly orderStatusEnum = OrderStatusEnum
	protected readonly orderAttributeEnum = OrderAttributeEnum
	protected readonly orderSideEnum = OrderSideEnum
	protected readonly positionSideEnum = PositionSideEnum

	constructor() {}

	private _checkCanEditParentOrder(order: OrderInterface | undefined): boolean {
		if (!order) return false
		if (order.order_class === this.orderClassEnum.oco) return false
		return order.type !== this.orderTypeEnum.market && [this.orderStatusEnum.new].includes(order.status)
	}

	private _checkCanEditChildrenOrders(order: OrderInterface | undefined): boolean {
		if (!order) return false
		// Получаем детей
		const childOrders: OrderInterface[] = order.children_order || []
		// Фильтруем детей, оставляя только стоп-лосс и тейк-профит
		const relevantChildOrders: OrderInterface[] = childOrders.filter((child: OrderInterface) =>
			[this.orderAttributeEnum.stopLoss, this.orderAttributeEnum.takeProfit].includes(child.order_attribute)
		)
		// Проверяем статусы для каждого стоп-лосса и тейк-профита
		return relevantChildOrders.some((child: OrderInterface) =>
			[this.orderStatusEnum.new, this.orderStatusEnum.held].includes(child.status)
		)
	}

	private _checkCanCancelParentOrder(order: OrderInterface | undefined): boolean {
		if (!order) return false
		return ![this.orderStatusEnum.filled, this.orderStatusEnum.canceled].includes(order.status)
	}

	private _checkCanCancelChildrenOrders(order: OrderInterface | undefined): boolean {
		if (!order) return false
		// Получаем детей
		const childOrders: OrderInterface[] = order.children_order || []
		// Фильтруем детей, оставляя только стоп-лосс и тейк-профит
		const relevantChildOrders: OrderInterface[] = childOrders.filter((child: OrderInterface) =>
			[this.orderAttributeEnum.stopLoss, this.orderAttributeEnum.takeProfit].includes(child.order_attribute)
		)
		// Проверяем статусы для каждого стоп-лосса и тейк-профита
		return relevantChildOrders.some((child: OrderInterface) =>
			![this.orderStatusEnum.filled, this.orderStatusEnum.canceled].includes(child.status)
		)
	}

	public transformOrders(orders: OrderInterface[]): OrderInterface[] {
		if (!orders) return []
		return orders.map((order: OrderInterface) => {
			return {
				...order,
				status_ru: OrderStatusRu[order.status] || 'Неизвестно',
				side_ru: OrderSideRu[order.side] || 'Неизвестно',
				children_order:
					order.children_order &&
					order.children_order.map((child: OrderInterface) => {
						return {
							...child,
							status_ru: OrderStatusRu[child.status] || 'Неизвестно',
							side_ru: OrderSideRu[child.side] || 'Неизвестно'
						}
					})
			}
		})
	}

	public getVisibleStopPrice(type: OrderTypeEnum | undefined): boolean {
		if (!type) return false
		return [this.orderTypeEnum.stop_limit, this.orderTypeEnum.stop].includes(type)
	}

	public getVisibleLimitPrice(type: OrderTypeEnum | undefined, orderClass?: OrderClassEnum): boolean {
		if (!type) return false
		if (orderClass && orderClass === this.orderClassEnum.oco) return false
		return [this.orderTypeEnum.stop_limit, this.orderTypeEnum.limit].includes(type)
	}

	public checkCanEdit(order: OrderInterface | undefined): boolean {
		if (!order) return false
		return this._checkCanEditParentOrder(order) || this._checkCanEditChildrenOrders(order)
	}

	public checkCanCancel(order: OrderInterface | undefined): boolean {
		if (!order) return false
		return this._checkCanCancelParentOrder(order) || this._checkCanCancelChildrenOrders(order)
	}

	public getVisibleAvgPrice(order: OrderInterface | undefined): boolean {
		return !order ? false : order.order_class === this.orderClassEnum.oco
	}

	public findStopLoss(order: OrderInterface | undefined): OrderInterface | undefined {
		if (!order) return
		const children: OrderInterface[] = order.children_order || []
		return children.find((child: OrderInterface) => child.order_attribute === this.orderAttributeEnum.stopLoss)
	}

	public findTakeProfit(order: OrderInterface | undefined): OrderInterface | undefined {
		if (!order) return
		const children: OrderInterface[] = order.children_order || []
		if (order.order_class === this.orderClassEnum.oco) return order
		return children.find((child: OrderInterface) => child.order_attribute === this.orderAttributeEnum.takeProfit)
	}

	public filterOrdersByPosition(position: PositionInterface | undefined, orders: OrderInterface[] | undefined): OrderInterface[] {
		if (!position || !orders) return []
		const filteredOrders: OrderInterface[] = orders.filter((order: OrderInterface) => order.symbol === position.symbol)

		const isOtoBracket = (orderClass: OrderClassEnum): boolean => [this.orderClassEnum.oto, this.orderClassEnum.bracket].includes(orderClass)
		const isBuy = (orderSide: OrderSideEnum): boolean => this.orderSideEnum.buy === orderSide
		const isFilled = (orderStatus: OrderStatusEnum): boolean => this.orderStatusEnum.filled === orderStatus

		if (position.side === this.positionSideEnum.long) {
			return filteredOrders.filter((order: OrderInterface) => {
				if (!isBuy(order.side)) return true
				return isFilled(order.status) && isOtoBracket(order.order_class)
			})
		}

		return filteredOrders.filter((order: OrderInterface) => {
			if (isBuy(order.side)) return true
			return isFilled(order.status) && isOtoBracket(order.order_class)
		})
	}

}
