<div class="mobile-content">
	<div class="mobile-content__wrapper">
		<div class="single-tab__header">
			<a href="https://investlink.io/">
				<div class="image-container verify-layout__header-logo">
					<img src="./assets/images/logo-dark.svg" alt="logo">
				</div>
			</a>
			<a href="https://investlink.io/" class="image-container single-tab__header-close">
				<img src="./assets/images/icons/x-dark.svg" alt="close">
			</a>
		</div>

		<div class="mobile-content__body">
			<div>
				<div class="mobile-content__download">
					<span class="mobile-content__download-title">Скачайте мобильное приложение Investlink: Trade & Invest для регистрации.</span>
					<div class="mobile-content__download__app">
						<a href="https://apps.apple.com/kz/app/investlink-trade-invest/id1669200360" target="_blank"
							 class="image-container mobile-content__download__app__link">
							<img src="./assets/images/download-app-store.svg" alt="download-app-store">
						</a>
						<a href="https://play.google.com/store/apps/details?id=broker.investlink.io.investlink_broker" target="_blank"
							 class="image-container mobile-content__download__app__link">
							<img src="./assets/images/download-google-play.svg" alt="download-google-play">
						</a>
					</div>
				</div>
			</div>
		</div>

		<div class="mobile-content__footer">
			<a [href]="termsOfUser" target="_blank" class="mobile__footer-item">Пользовательское соглашение</a>
			<a [href]="privacyPolicy" target="_blank" class="mobile__footer-item">Политика конфиденциальности</a>
		</div>
	</div>
</div>
