import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'

import { AppComponent } from './app.component'
import { RouterModule } from '@angular/router'
import { appRouting } from './app.routing'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { JwtInterceptor } from './modules/auth/interceptors/jwt.interceptor'
import { IndividualPagesModule } from './modules/individual-pages/individual-pages.module'
import { SlTpSidebarModule } from '../core/entities/sl-tp-entity/components/sl-tp-sidebar/sl-tp-sidebar.module'
import {
	ClosePositionSidebarModule
} from '../core/entities/position-entity/components/sidebars/close-position-sidebar/close-position-sidebar.module'
import { LoadingModule } from '../core/components/loading/loading.module'
import { NotificationModule } from '../core/components/notification/notification.module'
import { LoadingBarModule } from '../core/components/loading/loading-bar/loading-bar.module'
import { DrawerModule } from '../core/components/drawer/drawer.module'
import { MatDialogModule } from '@angular/material/dialog'

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		RouterModule.forRoot(appRouting),
		HttpClientModule,
		BrowserAnimationsModule,
		NotificationModule,
		IndividualPagesModule,
		SlTpSidebarModule,
		ClosePositionSidebarModule,
		LoadingModule,
		LoadingBarModule,
		DrawerModule,
		MatDialogModule
	],
	providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
	bootstrap: [AppComponent]
})
export class AppModule {
}
