<div class="single-tab">
	<div class="single-tab__wrapper">
		<div class="single-tab__header">
			<a href="https://investlink.io/">
				<div class="image-container verify-layout__header-logo">
					<img src="./assets/images/logo-dark.svg" alt="logo">
				</div>
			</a>
			<a href="https://investlink.io/" class="image-container single-tab__header-close">
				<img src="./assets/images/icons/x-dark.svg" alt="close">
			</a>
		</div>

		<div class="single-tab__body">
			<div class="single-tab-content">
				<img class="single-tab-content-image" src="./assets/images/icons/alert.svg" alt="alert">

				<h3 class="single-tab-content-title">Вы уже авторизованы на платформе в другой вкладке.</h3>

				<span class="single-tab-content-description">Для стабильной работы и защиты ваших торговых данных, пожалуйста, закройте эту вкладку.</span>

				<div class="single-tab-content__btns">
					<button type="button" class="btn btn-md btn-solid-gray btn-direct" (click)="close()">Закрыть</button>
					<button type="button" class="btn btn-md btn-primary btn-direct" (click)="tradeHere()">Торговать здесь</button>
				</div>
			</div>
		</div>

		<div class="single-tab__footer">
			<a [href]="termsOfUser" target="_blank" class="single-tab__footer-item">Пользовательское соглашение</a>
			<a [href]="privacyPolicy" target="_blank" class="single-tab__footer-item">Политика конфиденциальности</a>
		</div>
	</div>
</div>
