import {
	Component,
	ContentChildren,
	Input, OnChanges,
	QueryList, SimpleChanges
} from '@angular/core'
import { TableColumnComponent } from './table-column/table-column.component'
import { ActiveSortInterface } from './core/interfaces/active-sort.interface'
import { SortDirectionType } from './core/types/sort-direction.type'

@Component({
	selector: 'app-table',
	templateUrl: './table.component.html'
})
export class TableComponent implements OnChanges {

	@Input() items: any[] | undefined = []
	@Input() nestedProp: string
	@Input() noItemsText = 'Ничего не найдено'
	@Input() excludeBorder = false
	@Input() sizeBodyNoItems: 'default' | 'large' | 'medium' | 'small' = 'default'
	@Input() isLoading = false
	@Input() sortTable: ActiveSortInterface | undefined

	@ContentChildren(TableColumnComponent) columns: QueryList<TableColumnComponent>

	public shownItems: any[] = []

	constructor() {}

	private _convertToNumberIfPossible(value: string | number | null | undefined): number | string | null | undefined {
		return (typeof value === 'string' && !isNaN(+value)) ? +value : value
	}

	public sortItems() {
		if (!this.sortTable || !this.items) {
			this.shownItems = this.items || []
			return
		}

		this.shownItems = [...this.items].sort((a, b) => {
			const aValue: number | string | null | undefined = this._convertToNumberIfPossible(a[this.sortTable?.prop as string])
			const bValue: number | string | null | undefined = this._convertToNumberIfPossible(b[this.sortTable?.prop as string])

			if (aValue == null) return -1
			if (bValue == null) return 1

			const comparison: number = (typeof aValue === 'number' && typeof bValue === 'number') ?
				aValue - bValue :
				aValue.toString().localeCompare(bValue.toString())

			return this.sortTable?.direction === 'asc' ? comparison : -comparison
		})
	}

	public toggleSort(column: TableColumnComponent) {
		if (!column?.sortable) return

		const prop: string | undefined = column?.prop
		if (!prop) return

		const direction: SortDirectionType = this.sortTable?.direction === 'desc' ? 'asc' : 'desc'
		this.sortTable = { prop, direction }
		this.sortItems()
	}

	public getIsVisibleSort(column: TableColumnComponent, direction: SortDirectionType): boolean {
		return column.prop !== this.sortTable?.prop || direction === this.sortTable.direction
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['items']?.currentValue) {
			this.sortItems()
		}
		if (changes['sortTable']?.currentValue) {
			this.sortItems()
		}
	}

}
