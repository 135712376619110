import { NgModule } from '@angular/core'
import { EngineeringWorksComponent } from './engineering-works/engineering-works.component'
import { CommonModule } from '@angular/common'
import { SingleTabComponent } from './single-tab/single-tab.component'
import { MobileContentComponent } from './mobile-content/mobile-content.component'
import { QrDownloadAppComponent } from './qr-download-app/qr-download-app.component'
import { RouterLink } from '@angular/router'

@NgModule({
	declarations: [
		EngineeringWorksComponent,
		SingleTabComponent,
		MobileContentComponent,
		QrDownloadAppComponent
	],
	imports: [
		CommonModule,
		RouterLink
	],
	exports: [
		MobileContentComponent
	],
	providers: []
})
export class IndividualPagesModule { }
