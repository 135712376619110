<div class="qr-download-app">
	<div class="qr-download-app__wrapper">
		<div class="single-tab__header">
			<a href="https://investlink.io/">
				<div class="image-container verify-layout__header-logo">
					<img src="./assets/images/logo-dark.svg" alt="logo">
				</div>
			</a>
			<a href="https://investlink.io/" class="image-container single-tab__header-close">
				<img src="./assets/images/icons/x-dark.svg" alt="close">
			</a>
		</div>

		<div class="qr-download-app__body">
			<div>
				<div class="qr-download-app__download">
					<h2 class="qr-download-app__download-title">Скачайте мобильное приложение Investlink: Trade & Invest для регистрации.</h2>
					<div class="qr-download-app__download-qr image-container">
						<img src="./assets/images/qr-investlink.svg" alt="QR-Investlink">
					</div>
					<span class="qr-download-app__download-description">Просто наведите камеру на QR-код.</span>
					<div class="qr-download-app__download__app">
						<a href="https://apps.apple.com/kz/app/investlink-trade-invest/id1669200360" target="_blank" class="image-container">
							<img src="./assets/images/download-app-store.svg" alt="download-app-store">
						</a>
						<a href="https://play.google.com/store/apps/details?id=broker.investlink.io.investlink_broker" target="_blank" class="image-container">
							<img src="./assets/images/download-google-play.svg" alt="download-google-play">
						</a>
					</div>
				</div>
				<div class="qr-download-app__body__footer">
					<span class="qr-download-app__footer-description">Уже зарегистрированы?</span>
					<a [routerLink]="['/auth/sign-in']" class="qr-download-app__footer-description sign-up__footer-link">Войти</a>
				</div>
			</div>
		</div>

		<div class="qr-download-app__footer">
			<a [href]="termsOfUser" target="_blank" class="qr-download-app__footer-item">Пользовательское соглашение</a>
			<a [href]="privacyPolicy" target="_blank" class="qr-download-app__footer-item">Политика конфиденциальности</a>
		</div>
	</div>
</div>
