<table class="table">
	<thead>
	<tr class="table-headers">
		<th class="table-headers-item" *ngFor="let column of columns" (click)="toggleSort(column)">
			<div class="table-headers-item__wrapper">
				<span class="table-headers-item-title">{{ column.label }}</span>
				<div class="table-headers-item__sort" *ngIf="column?.sortable">
					<img src="./assets/images/icons/mini-arrow-top.svg" alt="mini-arrow-top" *ngIf="getIsVisibleSort(column, 'asc')">
					<img src="./assets/images/icons/mini-arrow-bottom.svg" alt="mini-arrow-bottom" *ngIf="getIsVisibleSort(column, 'desc')">
				</div>
			</div>
		</th>
	</tr>
	</thead>

	<tbody class="table-body"
				 *ngIf="!isLoading else skeleton"
				 [class.table-body-no-items-default]="!items?.length && sizeBodyNoItems === 'default'"
				 [class.table-body-no-items-large]="!items?.length && sizeBodyNoItems === 'large'"
				 [class.table-body-no-items-medium]="!items?.length && sizeBodyNoItems === 'medium'"
				 [class.table-body-no-items-small]="!items?.length && sizeBodyNoItems === 'small'"
	>
	<ng-container *ngIf="shownItems?.length else noItems">
		<ng-container *ngFor="let item of shownItems">

			<!-- Основные строки таблицы -->
			<ng-container *ngTemplateOutlet="tableRowTemplate; context: {rowItem: item}"></ng-container>

			<!-- Вложенные строки -->
			<ng-container *ngIf="item[nestedProp] && item[nestedProp].length">
				<ng-container *ngFor="let nestedItem of item[nestedProp]; let idx = index">
					<ng-container *ngTemplateOutlet="tableRowTemplate; context: {rowItem: nestedItem, isNested: true, isLastNested: idx === item[nestedProp].length - 1}"></ng-container>
				</ng-container>
			</ng-container>

		</ng-container>
	</ng-container>
	</tbody>
</table>

<!-- Шаблон строки таблицы -->
<ng-template #tableRowTemplate let-rowItem="rowItem" let-isNested="isNested" let-isLastNested="isLastNested">
	<tr class="table-row"
			[class.table-row-with-nested]="rowItem[nestedProp]?.length"
			[class.table-row-nested]="isNested"
			[class.table-row-nested-last]="isLastNested"
			[class.exclude-border]="excludeBorder"
	>
		<td class="table-row-item" *ngFor="let column of columns">
			<ng-container *ngIf="column.columnTemplate; else defaultColumnTemplate"
										[ngTemplateOutlet]="column.columnTemplate"
										[ngTemplateOutletContext]="{
												value: rowItem[column.prop],
												item: rowItem,
												isNested: isNested
										}">
			</ng-container>
			<ng-template #defaultColumnTemplate>
				{{ rowItem[column.prop] }}
			</ng-template>
		</td>
	</tr>
</ng-template>

<ng-template #noItems>
	<div class="table-no-items">
		<div class="image-container table-no-items-icon">
			<img src="./assets/images/icons/empty-folder.svg" alt="empty-folder">
		</div>
		<p class="table-no-items-description">{{noItemsText}}</p>
	</div>
</ng-template>

<ng-template #skeleton>
	<tr class="table-skeleton">
		<td *ngFor="let column of columns" class="table-skeleton-column">
			<ngx-skeleton-loader count="7" appearance="line"></ngx-skeleton-loader>
		</td>
	</tr>
</ng-template>
