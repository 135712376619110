import { Injectable } from '@angular/core'
import {
	ActivatedRouteSnapshot,
	CanActivate,
	CanActivateChild, Router,
	RouterStateSnapshot, UrlTree
} from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({
	providedIn: 'root'
})
export class SingleTabGuard implements CanActivate, CanActivateChild {

	private _channel: BroadcastChannel = new BroadcastChannel('single-tab-channel')

	constructor(
		private _router: Router
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		this._channel.postMessage('single-tab')

		this._channel.onmessage = (event: MessageEvent) => {
			this._router.navigate(['single-tab'])
			return false
		}

		return true
	}

	canActivateChild(
		childRoute: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.canActivate(childRoute, state)
	}

}
