import { NgModule } from '@angular/core'
import { ClickOutsideDirective } from './click-outside.directive'
import { DisableScrollDirective } from './disable-scroll.directive'

@NgModule({
	declarations: [
		ClickOutsideDirective,
		DisableScrollDirective
	],
	imports: [],
	exports: [
		ClickOutsideDirective,
		DisableScrollDirective
	],
	providers: []
})
export class DirectivesModule { }
