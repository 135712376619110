import {
	Component, EventEmitter,
	Input,
	OnDestroy,
	OnInit, Output
} from '@angular/core'
import {
	PositionInterface
} from '../../../interfaces/position.interface'
import { Subject, takeUntil } from 'rxjs'
import { OrderSideEnum } from '../../../../order-entity/enums/order-side.enum'
import { OrderTypeEnum } from '../../../../order-entity/enums/order-type.enum'
import { OrderStatusEnum } from '../../../../order-entity/enums/order-status.enum'
import { PositionSideEnum } from '../../../enums/position-side.enum'
import { OrderInterface } from '../../../../order-entity/interfaces/order.interface'
import { OrderService } from '../../../../order-entity/services/order.service'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { NotificationService } from '../../../../../components/notification/core/services/notification.service'
import { HttpErrorResponse } from '@angular/common/http'
import { MarketStatusEnum } from '../../../../market-status-entity/enums/market-status.enum'
import { MarketStatusService } from '../../../../market-status-entity/services/market-status.service'
import { ClosePositionInterface } from '../../../interfaces/close-position.interface'
import { PositionService } from '../../../services/position.service'
import {
	ConfirmClosePositionComponent
} from '../../dialogs/confirm-close-position/confirm-close-position.component'
import { OrderTermInterface } from '../../../../order-entity/interfaces/order-term.interface'
import { OrderTypes } from '../../../../order-entity/consts/order-types'
import { OrderTypeInterface } from '../../../../order-entity/interfaces/order-type.interface'
import { OrderTerms } from '../../../../order-entity/consts/order-terms'
import { OrderTermEnum } from '../../../../order-entity/enums/order-term.enum'
import {
	ConfirmCancelOrderSidebarComponent
} from '../../../../order-entity/components/sidebars/confirm-cancel-order-sidebar/confirm-cancel-order-sidebar.component'
import { DrawerService } from '../../../../../components/drawer/core/services/drawer.service'
import { Options } from 'ngx-slider-v2'
import { OrderClassEnum } from '../../../../order-entity/enums/order-class.enum'
import {
	OrderGeneralHelperService
} from '../../../../order-entity/services/order-general-helper.service'
import { PositionsHelperService } from '../../../services/positions-helper.service'
import { TextFormatService } from '../../../../../services/text-format.service'

@Component({
	selector: 'app-close-position-sidebar',
	templateUrl: './close-position-sidebar.component.html'
})
export class ClosePositionSidebarComponent implements OnDestroy, OnInit {

	@Input() position: PositionInterface | undefined
	@Output() closeDrawer: EventEmitter<boolean> = new EventEmitter<boolean>()

	protected readonly orderSideEnum = OrderSideEnum
	protected readonly orderTypeEnum = OrderTypeEnum
	protected readonly orderTermEnum = OrderTermEnum
	protected readonly orderStatusEnum = OrderStatusEnum
	protected readonly positionSideEnum = PositionSideEnum
	protected readonly orderTypes: OrderTypeInterface[] = OrderTypes
	protected readonly orderTerms: OrderTermInterface[] = OrderTerms
	protected readonly orderClassEnum = OrderClassEnum

	private _unSubscribeAll: Subject<any> = new Subject<any>()
	private marketStatus: MarketStatusEnum | null

	public orders: OrderInterface[] = []
	public isVisibleOrdersTable = true
	public filteredOrderTypes: OrderTypeInterface[] = []
	public filteredOrderTerms: OrderTermInterface[] = []

	public activeSliderValue = 0
	public activeQty: number | null = null
	public activePercent: number | null = null
	public qtyAvailable = 0

	public orderType: OrderTypeEnum
	public orderTerm: OrderTermEnum
	public limitPrice = 0
	public isCancelOpenOrders = false

	public minRange = 0
	public maxRange = 100
	public countStepsAfterStart = 4
	public step = 0

	get isActiveRange(): boolean {
		return this.qtyAvailable > 0
	}

	get totalQty(): number {
		if (!this.position) return 0
		return Math.abs(+this.position.qty)
	}

	get rangeOptions(): Options {
		this.step = Math.round(this.maxRange / (this.countStepsAfterStart))

		return {
			floor: this.minRange,
			ceil: this.maxRange,
			showTicks: true,
			showSelectionBar: true,
			step: this.step,
			enforceStep: false,
			disabled: !this.isActiveRange,
			translate: (percent: number): string => `${Math.round((percent / this.maxRange) * this.qtyAvailable)} шт.`
		}
	}

	/**
	 * Вернет доходность для таблицы
	 *
	 * @return {{ dollar: number, percent: number }} The dollar and percent profitability.
	 */
	get tableProfitability(): { dollar: number; percent: number } {
		if (!this.position) return { dollar: 0, percent: 0 }

		return {
			dollar: parseFloat(Number(this.position.unrealized_pl).toFixed(2)),
			percent: parseFloat((Number(this.position.unrealized_plpc) * 100).toFixed(2))
		}
	}

	constructor(
		private orderService: OrderService,
		private _dialog: MatDialog,
		private notificationService: NotificationService,
		private marketStatusService: MarketStatusService,
		private positionService: PositionService,
		private _drawerService: DrawerService,
		private _orderGeneralHelperService: OrderGeneralHelperService,
		private _positionsHelperService: PositionsHelperService,
		private _textFormatService: TextFormatService
	) {}

	private _initOrderInfo() {
		this.filteredOrderTypes = this.orderTypes.filter((orderType: OrderTypeInterface) =>
			[this.orderTypeEnum.market, this.orderTypeEnum.limit].includes(orderType.type))
		this.orderType = this.orderTypeEnum.market
		this.orderTerm = this.orderTermEnum.gtc
		this.limitPrice = this.position ? parseFloat(this.position?.current_price) : 0
		this.onChangeOrderType()
	}

	private subscribeOnGetSocketOrders() {
		this.orderService.onGetSocketOrders
			.pipe(takeUntil(this._unSubscribeAll))
			.subscribe((response: OrderInterface[] | null) => {
				response = response ? response : []
				this.orders = this._orderGeneralHelperService.filterOrdersByPosition(this.position, response)
				this.calcQtyAvailable()
				this.isVisibleOrdersTable = !!this.orders.length
			}, (error: HttpErrorResponse) => {
				this.notificationService.onShow('error', 'Ошибка', error?.error?.message || 'Не удалось получить ордера')
			})
	}

	private dropChanges() {
		this.qtyAvailable = 0
		this.activePercent = null
		this.onChangePercent()
		this.isCancelOpenOrders = false
		this.isVisibleOrdersTable = true
	}

	private subscribeMarketStatus() {
		this.marketStatusService.marketStatus$
			.pipe(takeUntil(this._unSubscribeAll))
			.subscribe((marketStatus: MarketStatusEnum | null) => {
				this.marketStatus = marketStatus
			})
	}

	private checkIsValidOrder(): boolean {
		if (!this.position) return false
		const showError = (message: string): boolean => {
			this.notificationService.onShow('error', 'Ошибка', message)
			return false
		}
		if ((this.activeQty && this.activeQty <= 0) || (this.activeQty && this.activeQty > this.qtyAvailable)) {
			return showError('Количество не может быть равно 0 и больше доступных')
		}
		return true
	}

	private closePosition(position: PositionInterface | undefined): void {
		if (!position) return
		const data: ClosePositionInterface = {
			symbol: position.symbol,
			qty: this.activeQty || 0,
			cancel_open_orders: this.isCancelOpenOrders,
			type: this.orderType,
			time_in_force: this.orderTerm === this.orderTermEnum.dayPlus ? this.orderTermEnum.day : this.orderTerm,
			extended_hours: this.orderType === this.orderTypeEnum.limit && this.orderTerm == this.orderTermEnum.dayPlus,
			...(this.orderType === this.orderTypeEnum.limit && { limit_price: this.limitPrice ? this.limitPrice.toString() : '0' })
		}
		this.positionService.closePosition(data)
			.pipe(takeUntil(this._unSubscribeAll))
			.subscribe(() => {
				this._dialog.closeAll()
				this.onClose()
				this.dropChanges()
				this.notificationService.onShow('success', 'Успешно', 'Позиция успешно закрыта')
			}, (error: HttpErrorResponse) => {
				this.notificationService.onShow('error', 'Ошибка', error?.error?.error?.message || 'Не удалось закрыть позицию')
				this._dialog.closeAll()
			})
	}

	private calcQtyAvailable() {
		this.qtyAvailable = this._positionsHelperService.calcQtyAvailable(this.position, this.orders)
	}

	public getClassByProfitability(value: number | undefined): string {
		return this._textFormatService.getClassByProfitability(value)
	}

	public onChangeCancelOpenOrders() {
		if (this.isCancelOpenOrders) {
			this.qtyAvailable = this.totalQty
		} else {
			this.calcQtyAvailable()
		}
	}

	public onFocusOutLimitPrice() {
		if (!this.limitPrice || this.limitPrice < 0) {
			this.limitPrice = 0
		}
	}

	public onClose() {
		this.closeDrawer.emit(true)
	}

	public openConfirmCancelOrder(order: OrderInterface) {
		this._drawerService.open(ConfirmCancelOrderSidebarComponent, ['right', 'start'], {
			inputs: { order }
		})
	}

	public onChangeSlider() {
		this.activePercent = this.activeSliderValue
		this.onChangePercent()
	}

	public onChangePercent() {
		if (!this.activePercent) {
			this.activeSliderValue = 0
			this.activeQty = null
			return
		}
		setTimeout(() => this.activeSliderValue = (this.activePercent || 0))
		this.activeQty = Math.round((this.activePercent / this.maxRange) * this.qtyAvailable)
	}

	public onChangeQty() {
		if (!this.activeQty) {
			this.activeSliderValue = 0
			this.activePercent = null
			return
		}
		this.activePercent = Math.round((this.activeQty / this.qtyAvailable) * this.maxRange)
		setTimeout(() => this.activeSliderValue = (this.activePercent || 0))
	}

	public onChangeOrderType() {
		if (this.orderType == this.orderTypeEnum.market) {
			this.filteredOrderTerms = this.orderTerms.filter((term: OrderTermInterface) =>
				[this.orderTermEnum.gtc, this.orderTermEnum.day].includes(term.term))
			return
		}
		this.filteredOrderTerms = this.orderTerms
	}

	public confirmClosePosition(): void {
		if (!this.position) return
		if (!this.checkIsValidOrder()) return
		if (this.marketStatus === MarketStatusEnum.marketOpen) {
			this.closePosition(this.position)
			return
		}
		const dialogRef: MatDialogRef<ConfirmClosePositionComponent> = this._dialog.open(ConfirmClosePositionComponent)
		dialogRef.componentInstance.onConfirm
			.pipe(takeUntil(this._unSubscribeAll))
			.subscribe(() => {
				this.closePosition(this.position)
			})
	}

	ngOnInit() {
		if (!this.position) return
		this.subscribeMarketStatus()
		this.dropChanges()
		this._initOrderInfo()
		this.subscribeOnGetSocketOrders()
		this.activeSliderValue = 100
		this.onChangeSlider()
	}

	ngOnDestroy() {
		this._unSubscribeAll.next(null)
		this._unSubscribeAll.complete()
	}

	protected readonly OrderClassEnum = OrderClassEnum
}
