<div class="operation-dialogs confirm-close-position">
	<div class="operation-dialogs__wrapper">
		<div class="operation-dialogs-icon image-container">
			<img src='./assets/images/icons/alert.svg' alt="alert">
		</div>

		<h3 class="operation-dialogs-title">Позиция будет закрыта, когда откроется рынок</h3>
		<p class="operation-dialogs-description">
			При использовании функции "Закрытие позиции" происходит автоматическое выставление Market ордера на продажу (если позиция была открыта в Long) или покупку (если позиция была открыта в Short).
		</p>

		<p class="operation-dialogs-description">
			Пожалуйста, имейте в виду, что Market ордер будет исполнен сразу же после открытия рынка, но цена может отличаться от текущей стоимости из-за возможной высокой волатильности.
		</p>

		<p class="operation-dialogs-description">
			Рекомендуем быть внимательными к текущим рыночным условиям и принимать решения с учетом возможных колебаний цены.
		</p>

		<div class="operation-dialogs__btns">
			<button type="button" class="btn btn-md btn-primary btn-direct" (click)="confirm()">Закрыть позицию</button>
			<button type="button" class="btn btn-md btn-solid-gray btn-direct" (click)="close()">Отмена</button>
		</div>
	</div>
</div>
