import { Component, OnDestroy, OnInit } from '@angular/core'
import { NotificationService } from './core/services/notification.service'
import { NotificationInterface } from './core/interfaces/notification.interface'
import { Subject, takeUntil } from 'rxjs'

@Component({
	selector: 'app-notification',
	templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit, OnDestroy {

	private _unSubscribeAll: Subject<any> = new Subject<any>()

	public notificationData: NotificationInterface | null = null

	private _isOpen = false

	get classesNotification(): string[] {
		const notificationType: string = this.notificationData?.type || ''
		const opened: string = this._isOpen ? 'opened' : ''

		return [notificationType, opened]
	}

	constructor(
		private _notificationService: NotificationService
	) {}

	private _subscribeNotificationDataSignal() {
		this._notificationService.notificationDataSignal
			.pipe(takeUntil(this._unSubscribeAll))
			.subscribe((response: NotificationInterface | null) => {
				this.notificationData = response
			})
	}

	private _subscribeNotificationOpenSignal() {
		this._notificationService.notificationOpenSignal
			.pipe(takeUntil(this._unSubscribeAll))
			.subscribe((response: boolean) => this._isOpen = response)
	}

	public onHide() {
		this._notificationService.onHide()
	}

	ngOnInit(): void {
		this._subscribeNotificationDataSignal()
		this._subscribeNotificationOpenSignal()
	}

	ngOnDestroy() {
		this._unSubscribeAll.next(null)
		this._unSubscribeAll.complete()
	}

}
