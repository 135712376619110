import { Injectable } from '@angular/core'
import { PositionInterface } from '../entities/position-entity/interfaces/position.interface'
import { PositionSideEnum } from '../entities/position-entity/enums/position-side.enum'

@Injectable({ providedIn: 'root' })
export class TextFormatService {

	protected readonly positionSideEnum = PositionSideEnum

	constructor() { }

	public getClassByProfitability(value: number | undefined): string {
		if (!value) return 'profitability-gray'
		if (value > 0) return 'profitability-success'
		if (value < 0) return 'profitability-danger'
		return 'profitability-gray'
	}

	public getTextByProfitability(value: number | undefined, type: 'dollar' | 'percent'): string {
		if (type === 'dollar') {
			if (!value) return `$0`
			if (value > 0) return `+$${value}`
			if (value < 0) return `-$${Math.abs(value)}`
			return `$0`
		}

		if (type === 'percent') {
			if (!value) return `0%`
			if (value > 0) return `+${value}%`
			if (value < 0) return `-${Math.abs(value)}%`
			return `$0`
		}

		return '0'
	}

}
