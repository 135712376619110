import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver, ComponentRef, Input, OnDestroy,
	ViewChild,
	ViewContainerRef
} from '@angular/core'
import { DrawerBindingsInterface } from './core/interfaces/drawer-bindings.interface'
import { DrawerService } from './core/services/drawer.service'
import { DrawerSideType } from './core/types/drawer-side.type'
import { Subject, takeUntil } from 'rxjs'
import { DrawerAlignmentType } from './core/types/drawer-alignment.type'

@Component({
	selector: 'app-drawer',
	templateUrl: './drawer.component.html'
})
export class DrawerComponent implements OnDestroy {

	@Input() drawerRef: ComponentRef<DrawerComponent> | undefined
	@ViewChild('drawerContent', { read: ViewContainerRef, static: true }) drawerContent: ViewContainerRef | undefined

	private _unSubscribeAll: Subject<any> = new Subject<any>()

	public side: DrawerSideType = 'right'
	public alignment: DrawerAlignmentType = 'end'

	get additionalClasses(): string {
		return `drawer-${this.side} drawer-${this.alignment}`
	}

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private _drawerService: DrawerService
	) {}

	public loadContent(
		component: any,
		location: [DrawerSideType, DrawerAlignmentType],
		bindings?: DrawerBindingsInterface | undefined
	) {
		if (!this.drawerContent) return
		// content component
		const componentFactory: ComponentFactory<any> = this.componentFactoryResolver.resolveComponentFactory(component)
		const componentRef: ComponentRef<any> = this.drawerContent.createComponent(componentFactory)

		// location
		this.side = location[0]
		this.alignment = location[1]

		// @Input()
		if (bindings && bindings?.inputs) {
			Object.assign(componentRef.instance, bindings.inputs)
		}

		// @Output()
		if (bindings && bindings?.outputs) {
			for (const [output, handler] of Object.entries(bindings.outputs)) {
				if (componentRef.instance[output] && componentRef.instance[output]?.subscribe) {
					componentRef.instance[output]
						.pipe(takeUntil(this._unSubscribeAll))
						.subscribe(handler)
				}
			}
		}
	}

	public close() {
		if (!this.drawerRef) return
		this._drawerService.close(this.drawerRef)
	}

	ngOnDestroy() {
		this._unSubscribeAll.next(null)
		this._unSubscribeAll.complete()
	}

}
