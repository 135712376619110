import { NgModule } from '@angular/core'
import { DrawerService } from './core/services/drawer.service'
import { DrawerComponent } from './drawer.component'
import { CommonModule } from '@angular/common'

@NgModule({
	declarations: [
		DrawerComponent
	],
	imports: [
		CommonModule
	],
	exports: [
		DrawerComponent
	],
	providers: [
		DrawerService
	]
})
export class DrawerModule {}
