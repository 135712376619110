import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { UserService } from '../../user-entity/services/user-service'
import { OrdersResponseInterface } from '../interfaces/orders-response.interface'
import { ChangeOrderInterface } from '../interfaces/change-order.interface'
import { WebSocketService } from '../../../services/web-socket.service'
import { ChangeComplexOrderInterface } from '../interfaces/change-complex-order.interface'
import { OrderInterface } from '../interfaces/order.interface'
import { CreateOrderInterface } from '../interfaces/create-order.interface'
import { CreateOrderFromPositionInterface } from '../interfaces/create-order-from-position.interface'
import { OrderGeneralHelperService } from './order-general-helper.service'
import { OrderStateEnum } from '../enums/order-state.enum'

@Injectable({ providedIn: 'root' })
export class OrderService {

	public onGetSocketOrders: BehaviorSubject<OrderInterface[] | null> = new BehaviorSubject<OrderInterface[] | null>(null)

	constructor(
		private _http: HttpClient,
		private _userService: UserService,
		private webSocketService: WebSocketService,
		private _orderGeneralHelperService: OrderGeneralHelperService
	) {}

	public getAllOrders(state: OrderStateEnum, page = 1, pageSize = 1000): Observable<OrdersResponseInterface> {
		let params: HttpParams = new HttpParams()
		if (state) {
			params = params.set('status', state)
			params = params.set('page', page)
			params = params.set('page_size', pageSize)
		}
		return this._http.get<OrdersResponseInterface>(`${ environment.apiUrl }/orders/order_history/`, { params })
			.pipe(
				map((response: OrdersResponseInterface) => {
					return {
						...response,
						orders: this._orderGeneralHelperService.transformOrders(response.orders)
					}
				})
			)
	}

	public cancelOrder(orderId: string): Observable<OrderInterface> {
		return this._http.post<OrderInterface>(`${ environment.apiUrl }/orders/cancel_order/`, { order_id: orderId })
			.pipe(
				map((response: OrderInterface) => {
					return this._orderGeneralHelperService.transformOrders([response])[0]
				})
			)
	}

	public getSocketOrders(status: string): Observable<OrderInterface[]> {
		if (!this._userService.userDetailLocal) return of([])
		this.webSocketService.connect(`${environment.socketUrl}/user_orders_v2/`)

		this.webSocketService.sendMessage({
			action: 'get_orders',
			request_id: 150060530,
			user_id: this._userService.userDetailLocal.id,
			status
		})

		return this.webSocketService.onMessage()
			.pipe(
				map((response: OrderInterface[]) => {
					return this._orderGeneralHelperService.transformOrders(response)
				}),
				tap((response: OrderInterface[]) => {
					this.onGetSocketOrders.next(response)
				})
			)
	}

	public changeOrder(data: ChangeOrderInterface): Observable<OrderInterface> {
		return this._http.patch<OrderInterface>(`${environment.apiUrl}/orders/replace_order/`, data)
			.pipe(
				map((response: OrderInterface) => this._orderGeneralHelperService.transformOrders([response])[0])
			)
	}

	public changeComplexOrder(data: ChangeComplexOrderInterface): Observable<OrderInterface> {
		return this._http.patch<OrderInterface>(`${environment.apiUrl}/orders/replace_advanced_order/`, data)
			.pipe(
				map((response: OrderInterface) => this._orderGeneralHelperService.transformOrders([response])[0])
			)
	}

	public createOrder(order: Partial<CreateOrderInterface>): Observable<OrderInterface> {
		return this._http.post<OrderInterface>(`${ environment.apiUrl }/orders/create_order/`, order)
			.pipe(
				map((response: OrderInterface) => this._orderGeneralHelperService.transformOrders([response])[0])
			)
	}

	public createOrderFromPosition(order: CreateOrderFromPositionInterface): Observable<OrderInterface> {
		return this._http.post<OrderInterface>(`${ environment.apiUrl }/orders/create_order_from_position/`, order)
	}

}
