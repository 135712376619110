import {
	Component, ComponentRef, EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core'
import { PositionInterface } from '../../../position-entity/interfaces/position.interface'
import { Subject, takeUntil } from 'rxjs'
import { OrderInterface } from '../../../order-entity/interfaces/order.interface'
import { Options } from 'ngx-slider-v2'
import { OrderService } from '../../../order-entity/services/order.service'
import { HttpErrorResponse } from '@angular/common/http'
import { NotificationService } from '../../../../components/notification/core/services/notification.service'
import { OrderTypeEnum } from '../../../order-entity/enums/order-type.enum'
import { OrderSideEnum } from '../../../order-entity/enums/order-side.enum'
import { FormBuilder, FormGroup } from '@angular/forms'
import { PositionSideEnum } from '../../../position-entity/enums/position-side.enum'
import { CreateOrderFromPositionInterface } from '../../../order-entity/interfaces/create-order-from-position.interface'
import { StepsCounterInterface } from '../../../../interfaces/steps-counter.interface'
import { SlTpHelperService } from '../../services/sl-tp-helper.service'
import { OrderClassEnum } from '../../../order-entity/enums/order-class.enum'
import {
	ConfirmCancelOrderSidebarComponent
} from '../../../order-entity/components/sidebars/confirm-cancel-order-sidebar/confirm-cancel-order-sidebar.component'
import { DrawerService } from '../../../../components/drawer/core/services/drawer.service'
import { DrawerComponent } from '../../../../components/drawer/drawer.component'
import {
	OrderGeneralHelperService
} from '../../../order-entity/services/order-general-helper.service'
import { PositionsHelperService } from '../../../position-entity/services/positions-helper.service'
import { SettingsSlTpInterface } from '../../interfaces/settings-sl-tp.interface'
import { TextFormatService } from '../../../../services/text-format.service'

@Component({
	selector: 'app-sl-tp-sidebar',
	templateUrl: './sl-tp-sidebar.component.html'
})
export class SlTpSidebarComponent implements OnInit, OnDestroy {

	@Input() position: PositionInterface | undefined
	@Output() closeDrawer: EventEmitter<boolean> = new EventEmitter<boolean>()

	protected readonly orderSideEnum = OrderSideEnum
	protected readonly orderTypeEnum = OrderTypeEnum
	protected readonly positionSideEnum = PositionSideEnum
	protected readonly orderClassEnum = OrderClassEnum

	private _unSubscribeAll: Subject<any> = new Subject<any>()

	public orders: OrderInterface[] = []
	public isVisibleOrdersTable = true

	public stopLossForm: FormGroup
	public stopLossActive = false
	public takeProfitForm: FormGroup
	public takeProfitActive = false

	public activeSliderValue = 0
	public activeQty: number | null = null
	public activePercent: number | null = null
	public qtyAvailable = 0

	// range
	public minRange = 0
	public maxRange = 100
	public countStepsAfterStart = 4
	public step = 0

	get totalQty(): number {
		if (!this.position) return 0
		return Math.abs(+this.position.qty)
	}

	get rangeOptions(): Options {
		this.step = Math.round(this.maxRange / (this.countStepsAfterStart))

		return {
			floor: this.minRange,
			ceil: this.maxRange,
			showTicks: true,
			showSelectionBar: true,
			step: this.step,
			enforceStep: false,
			disabled: !this.isActiveRange,
			translate: (percent: number): string => `${Math.round((percent / this.maxRange) * this.qtyAvailable)} шт.`
		}
	}

	get isActiveRange(): boolean {
		return this.qtyAvailable > 0
	}

	get targetPrice(): number {
		return this.position ? +this.position.avg_entry_price : 0
	}

	get stepsPricesStopLoss(): StepsCounterInterface {
		const { price } = this.stopLossForm.value
		return this._slTpHelperService.getStepByPrice(price)
	}

	get stepsPricesTakeProfit(): StepsCounterInterface {
		const { price } = this.takeProfitForm.value
		return this._slTpHelperService.getStepByPrice(price)
	}

	/**
	 * Вернет доходность для таблицы
	 *
	 * @return {{ dollar: number, percent: number }} The dollar and percent profitability.
	 */
	get tableProfitability(): { dollar: number; percent: number } {
		if (!this.position) return { dollar: 0, percent: 0 }

		return {
			dollar: parseFloat(Number(this.position.unrealized_pl).toFixed(2)),
			percent: parseFloat((Number(this.position.unrealized_plpc) * 100).toFixed(2))
		}
	}

	constructor(
		private orderService: OrderService,
		private notificationService: NotificationService,
		private formBuilder: FormBuilder,
		private _slTpHelperService: SlTpHelperService,
		private _positionsHelperService: PositionsHelperService,
		private _orderGeneralHelperService: OrderGeneralHelperService,
		private _drawerService: DrawerService,
		private _textFormatService: TextFormatService
	) {}

	private initStopLossForm() {
		this.stopLossForm = this.formBuilder.group({
			percent: [{ value: null, disabled: true }],
			price: [{ value: null, disabled: true }],
			dollar: [{ value: null, disabled: true }]
		})
	}

	private initTakeProfitForm() {
		this.takeProfitForm = this.formBuilder.group({
			percent: [{ value: null, disabled: true }],
			price: [{ value: null, disabled: true }],
			dollar: [{ value: null, disabled: true }]
		})
	}

	private dropChanges() {
		this.qtyAvailable = 0
		this.activePercent = null
		this.onChangePercent()
		this.isVisibleOrdersTable = true
		this.toggleActiveSlTp('stopLoss', false)
		this.toggleActiveSlTp('takeProfit', false)
		this.stopLossForm.reset()
		this.takeProfitForm.reset()
	}

	private _getPercentTakeProfit(): number {
		if (!this.position) return 1
		const { market_value: marketValue, cost_basis: costBasis, qty, avg_entry_price: avgEntryPrice } = this.position
		if (this.position.side === this.positionSideEnum.short) {
			return (+marketValue - +costBasis) / (+qty * +avgEntryPrice) * -100 - 1
		} else {
			return (+marketValue - +costBasis) / (+qty * +avgEntryPrice) * 100 + 1
		}
	}

	private subscribeGetSocketOrders() {
		this.orderService.onGetSocketOrders
			.pipe(takeUntil(this._unSubscribeAll))
			.subscribe((response: OrderInterface[] | null) => {
				response = response ?? []
				this.orders = this._orderGeneralHelperService.filterOrdersByPosition(this.position, response)
				this.qtyAvailable = this._positionsHelperService.calcQtyAvailable(this.position, this.orders)
				this.isVisibleOrdersTable = !!this.orders.length
			})
	}

	public getClassByProfitability(value: number | undefined): string {
		return this._textFormatService.getClassByProfitability(value)
	}

	public onClose() {
		this._drawerService.closeAll()
	}

	public openConfirmCancelOrder(order: OrderInterface | undefined) {
		const drawerRef: ComponentRef<DrawerComponent> =	this._drawerService.open(ConfirmCancelOrderSidebarComponent, ['right', 'start'], {
			inputs: { order },
			outputs: { closeDrawer: () => this._drawerService.close(drawerRef) }
		})
	}

	public toggleActiveSlTp(type: 'stopLoss' | 'takeProfit', value?: boolean) {
		const form: FormGroup = type === 'stopLoss' ? this.stopLossForm : this.takeProfitForm
		const percentValue: number = type === 'stopLoss' ? -1 : this._getPercentTakeProfit()
		let isActive: boolean = type === 'stopLoss' ? this.stopLossActive : this.takeProfitActive
		isActive = value !== undefined ? value : !isActive

		if (type === 'stopLoss') this.stopLossActive = isActive
		else this.takeProfitActive = isActive

		if (isActive) {
			form.enable()
			form.patchValue({ percent: percentValue })
			this.changeSlTp(type, 'percent')
		} else {
			form.disable()
			type === 'stopLoss' ? this.initStopLossForm() : this.initTakeProfitForm()
		}
	}

	public changeSlTp(
		type: 'stopLoss' | 'takeProfit',
		field: 'percent' | 'price' | 'dollar'
	) {
		const form: FormGroup = type === 'stopLoss' ? this.stopLossForm : this.takeProfitForm
		const value: number = form.get(field)?.value || 0
		if (!value) return
		const isShort: boolean = this.position?.side === this.positionSideEnum.short
		form.patchValue(this._slTpHelperService.calcSlTp(isShort, (this.activeQty || 0), this.targetPrice, field, value))
	}

	public onChangeSlider() {
		this.activePercent = this.activeSliderValue
		this.onChangePercent()
	}

	public onChangePercent() {
		if (!this.activePercent) {
			this.activeSliderValue = 0
			this.activeQty = null
			return
		}
		this.activeSliderValue = this.activePercent
		this.activeQty = Math.round((this.activePercent / this.maxRange) * this.qtyAvailable)
		this.stopLossActive && this.changeSlTp('stopLoss', 'percent')
		this.takeProfitActive && this.changeSlTp('takeProfit', 'percent')
	}

	public onChangeQty() {
		if (!this.activeQty) {
			this.activeSliderValue = 0
			this.activePercent = null
			return
		}
		this.activePercent = Math.round((this.activeQty / this.qtyAvailable) * this.maxRange)
		this.activeSliderValue = this.activePercent
		this.stopLossActive && this.changeSlTp('stopLoss', 'percent')
		this.takeProfitActive && this.changeSlTp('takeProfit', 'percent')
	}

	public createOrder() {
		if (!this.position) return
		const stopLoss: { isActive: boolean, value: number } =
		{ isActive: this.stopLossActive, value: this.stopLossForm.value.price }
		const takeProfit: { isActive: boolean, value: number } =
		{ isActive: this.takeProfitActive, value: this.takeProfitForm.value.price }

		if (!this._slTpHelperService
			.checkIsValidOrder(this.position, { active: (this.activeQty || 0), available: this.qtyAvailable }, stopLoss, takeProfit)
		) return

		const newOrder: CreateOrderFromPositionInterface = this._slTpHelperService
			.collectOrderForCreate(this.position, (this.activeQty || 0), stopLoss, takeProfit)

		this.orderService.createOrderFromPosition(newOrder)
			.pipe(takeUntil(this._unSubscribeAll))
			.subscribe(() => {
				this.notificationService.onShow('success', 'Ордер создан', `Ордер ${ newOrder.symbol } создан`)
				this.onClose()
				this.dropChanges()
			}, (error: HttpErrorResponse) => {
				this.notificationService.onShow('error', 'Ошибка создания ордера', error?.error?.error?.message || 'Не удалось создать ордер')
			})
	}

	ngOnInit() {
		this.initStopLossForm()
		this.initTakeProfitForm()
		this.dropChanges()
		this.subscribeGetSocketOrders()
		this.activeSliderValue = 100
		this.onChangeSlider()
	}

	ngOnDestroy() {
		this._unSubscribeAll.next(null)
		this._unSubscribeAll.complete()
	}

}
