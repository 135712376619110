import {
	ApplicationRef,
	ComponentFactory,
	ComponentFactoryResolver, ComponentRef,
	Injectable,
	Injector
} from '@angular/core'
import { DrawerComponent } from '../../drawer.component'
import { DrawerBindingsInterface } from '../interfaces/drawer-bindings.interface'
import { DrawerSideType } from '../types/drawer-side.type'
import { ClassDrawerOpened } from '../consts/class-drawer-opened'
import { DrawerAlignmentType } from '../types/drawer-alignment.type'

@Injectable()
export class DrawerService {

	protected readonly classDrawerOpened: string = ClassDrawerOpened

	private _drawerComponentRefs: ComponentRef<DrawerComponent>[] = []

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector
	) {}

	public open(
		component: any,
		location: [DrawerSideType, DrawerAlignmentType],
		bindings?: DrawerBindingsInterface | undefined
	): ComponentRef<DrawerComponent> {
		const componentFactory: ComponentFactory<DrawerComponent> = this.componentFactoryResolver.resolveComponentFactory(DrawerComponent)
		const componentRef: ComponentRef<DrawerComponent> = componentFactory.create(this.injector)

		this.appRef.attachView(componentRef.hostView)

		const domElem: HTMLElement = (componentRef.hostView as any).rootNodes[0] as HTMLElement
		document.body.appendChild(domElem)

		componentRef.instance.drawerRef = componentRef
		componentRef.instance.loadContent(component, location, bindings)

		this._drawerComponentRefs.push(componentRef)

		setTimeout(() => {
			const drawerEl: HTMLElement | null = domElem.querySelector('.drawer')
			drawerEl?.classList.add(this.classDrawerOpened)
		}, 100)

		return componentRef
	}

	public close(drawerComponentRef: ComponentRef<DrawerComponent>) {
		const index: number = this._drawerComponentRefs.indexOf(drawerComponentRef)

		if (index !== -1) {
			const domElem: HTMLElement = (drawerComponentRef.hostView as any).rootNodes[0] as HTMLElement
			const drawerEl: HTMLElement | null = domElem.querySelector('.drawer')
			drawerEl?.classList.remove(this.classDrawerOpened)

			setTimeout(() => {
				document.body.removeChild(domElem)

				this.appRef.detachView(drawerComponentRef.hostView)
				drawerComponentRef.destroy()

				this._drawerComponentRefs.splice(index, 1)
			}, 500)
		}
	}

	public closeAll() {
		this._drawerComponentRefs.forEach((drawerRef: ComponentRef<DrawerComponent>) => {
			const domElem: HTMLElement = (drawerRef.hostView as any).rootNodes[0] as HTMLElement
			const drawerEl: HTMLElement | null = domElem.querySelector('.drawer')
			drawerEl?.classList.remove(this.classDrawerOpened)
		})

		setTimeout(() => {
			this._drawerComponentRefs = this._drawerComponentRefs.filter((drawerRef: ComponentRef<DrawerComponent>) => {
				const domElem: HTMLElement = (drawerRef.hostView as any).rootNodes[0] as HTMLElement
				document.body.removeChild(domElem)

				this.appRef.detachView(drawerRef.hostView)
				drawerRef.destroy()

				return false
			})
		}, 500)
	}

}
