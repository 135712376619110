import { OrderTermInterface } from '../interfaces/order-term.interface'
import { OrderTermEnum } from '../enums/order-term.enum'

export const OrderTerms: OrderTermInterface[] = [
	{ title: 'DAY', description: 'Day', term: OrderTermEnum.day },
	{ title: 'DAY+', description: 'Day+', term: OrderTermEnum.dayPlus },
	{ title: 'GTC', description: 'Good till Cancelled', term: OrderTermEnum.gtc },
	{ title: 'IOC', description: 'Immediate or Cancelled', term: OrderTermEnum.ioc },
	{ title: 'OPG', description: 'At the Open', term: OrderTermEnum.opg },
	{ title: 'CLS', description: 'At the Close', term: OrderTermEnum.cls }
]
