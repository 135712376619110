<div class="ng-select-group {{classes}}"
		 [class.ng-select-group-opened]="isOpen"
		 (click)="toggleSelect(!isOpen)"
		 appClickOutside
		 (clickOutside)="toggleSelect(false)">
	<label class="ng-select-group-label" for="select">{{label}}</label>
	<ng-select
		id="select"
		[items]="items"
		[bindLabel]="bindLabel"
		[bindValue]="bindValue"
		[(ngModel)]="ngModel"
		[clearable]="clearable"
		(ngModelChange)="onNgSelectChange($event)"
		(open)="toggleSelect(true)"
		(close)="toggleSelect(false)"
		[isOpen]="isOpen"
	></ng-select>
	<div class="ng-select-group-arrow">
		<img src='../../../assets/images/icons/arrow-bottom.svg' alt="arrow">
	</div>
</div>
