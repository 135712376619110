import { Injectable } from '@angular/core'
import { OrderStatusRu } from '../../order-entity/consts/order-status-ru'
import { OrderSideRu } from '../../order-entity/consts/order-side-ru'
import { PositionInterface } from '../interfaces/position.interface'
import { DealInterface } from '../../deal-entity/interfaces/deal.interface'
import { AssetInterface } from '../../asset-entity/interfaces/asset.interface'
import { OrderSideEnum } from '../../order-entity/enums/order-side.enum'
import { PositionSideEnum } from '../enums/position-side.enum'
import { OrderInterface } from '../../order-entity/interfaces/order.interface'

@Injectable({
	providedIn: 'root'
})
export class PositionsHelperService {

	protected readonly orderStatusRu = OrderStatusRu
	protected readonly orderSideRu = OrderSideRu
	protected readonly orderSideEnum = OrderSideEnum
	protected readonly positionSideEnum = PositionSideEnum

	constructor() {}

	public transformDeals(deals: DealInterface[]): DealInterface[] {
		if (!deals) return []

		return deals.map((deal: DealInterface) => {
			return {
				...deal,
				order_status_ru: this.orderStatusRu[deal.order_status] || 'Неизвестно',
				side_ru: this.orderSideRu[deal.side] || this.orderSideRu[this.orderSideEnum.sell]
			}
		})
	}

	public getStubSymbol(position: PositionInterface | undefined, assetInfo: AssetInterface | undefined): string {
		if (!position) return ''
		if (!assetInfo || !assetInfo.ticker) return position.symbol.slice(0, 2)
		return assetInfo.ticker.length > 2 ? assetInfo.ticker.slice(0, 2) : assetInfo.ticker
	}

	public getResultProfitability(item: PositionInterface, type: 'dollar' | 'percent'): number {
		if (!item) return 0
		if (type === 'dollar') {
			return +(+item.market_value - +item.cost_basis).toFixed(2)
		} else {
			const difference: number = +item.market_value - +item.cost_basis
			const baseValue: number = +item.qty * +item.avg_entry_price
			const ratio: number = difference / baseValue
			const factor: number = item.side === this.positionSideEnum.long ? 100 : -100
			return +(ratio * factor).toFixed(2)
		}
	}

	public calcQtyAvailable(position: PositionInterface | undefined, orders: OrderInterface[] | undefined): number {
		if (!position || !orders) return 0
		const sumQty: number = orders.reduce((acc: number, order: OrderInterface) => acc + order.qty, 0)
		const diff: number = position.side === this.positionSideEnum.short ? +position.qty + sumQty : +position.qty - sumQty
		return Math.floor(Math.abs(diff))
	}

}
