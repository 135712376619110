import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { privacyPolicy, termsOfUser } from '../../../../core/consts/docs'

@Component({
	selector: 'app-single-tab',
	templateUrl: './single-tab.component.html'
})
export class SingleTabComponent {

	protected readonly termsOfUser: string = termsOfUser
	protected readonly privacyPolicy: string = privacyPolicy

	constructor(
		private _router: Router
	) {}

	public close() {
		window.location.href = 'https://investlink.io'
	}

	public tradeHere() {
		this._router.navigate(['/'])
	}

}
